<template>
  <b-sidebar position="static" :fullheight="true" open>
    <div class="p-5">
      <div class="block has-text-centered my-5 py-5">
        <router-link
          :to="{
            name: 'Home'
          }"
        >
          <img src="@/assets/logo_white.png" width="100%" />
        </router-link>
      </div>
      <div v-if="false" class="columns">
        <div class="column">
          <div v-if="userDetail" class="box welcome-container">
            <div class="columns">
              <div class="column">
                <p class="bold">
                  {{ userDetail.firstName + " " + userDetail.lastName }}
                </p>
                <p>
                  {{ userDetail.title }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <router-link :to="{ name: 'AddContent' }">
        <div v-if="true" class="columns" style="cursor: pointer">
          <div class="column">
            <div class="box add-content-container py-3 px-4">
              <div class="columns">
                <div class="column">
                  <p class="bold">Add Content</p>
                </div>
                <div class="column is-narrow">
                  <i class="mdi mdi-plus-circle"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </router-link>
      <b-menu class="is-custom-mobile mt-5">
        <b-menu-list label="Library">
          <b-menu-item
            icon="view-dashboard"
            label="Dashboard"
            tag="router-link"
            :to="{ name: 'Dashboard' }"
          ></b-menu-item>

          <b-menu-item
            icon="palette-advanced"
            label="Digital Media"
            tag="router-link"
            :to="{ name: 'DigitalMedia' }"
          ></b-menu-item>

          <b-menu-item icon="book-open-page-variant">
            <template #label="props">
              Books
              <b-icon
                class="is-pulled-right"
                :icon="props.expanded ? '' : ''"
              ></b-icon>
            </template>
            <b-menu-item
              icon="book-open-page-variant"
              label="Books"
              tag="router-link"
              :to="{ name: 'Books' }"
            ></b-menu-item>

            <b-menu-item
              icon="book-open-page-variant"
              label="E-Books"
              tag="router-link"
              :to="{ name: 'EBooks' }"
            ></b-menu-item>
          </b-menu-item>

          <b-menu-item icon="notebook">
            <template #label="props">
              Journals
              <b-icon
                class="is-pulled-right"
                :icon="props.expanded ? '' : ''"
              ></b-icon>
            </template>
            <b-menu-item
              icon="notebook"
              label="Journals"
              tag="router-link"
              :to="{ name: 'Journals' }"
            ></b-menu-item>
            <b-menu-item
              icon="notebook"
              label="E-Journals"
              tag="router-link"
              :to="{ name: 'EJournals' }"
            ></b-menu-item>
          </b-menu-item>

          <b-menu-item icon="file-document-multiple-outline">
            <template #label="props">
              Research Articles
              <b-icon
                class="is-pulled-right"
                :icon="props.expanded ? '' : ''"
              ></b-icon>
            </template>
            <b-menu-item
              icon="file-document-multiple-outline"
              label="Research Articles"
              tag="router-link"
              :to="{ name: 'Research' }"
            ></b-menu-item>

            <b-menu-item
              icon="file-document-multiple-outline"
              label="E-Research Articles"
              tag="router-link"
              :to="{ name: 'EResearch' }"
            ></b-menu-item>
          </b-menu-item>

          <b-menu-item icon="newspaper-variant-outline">
            <template #label="props">
              Newspaper Articles
              <b-icon
                class="is-pulled-right"
                :icon="props.expanded ? '' : ''"
              ></b-icon>
            </template>
            <!-- <b-menu-item
              icon="newspaper-variant-outline"
              label="Newspaper Articles"
              tag="router-link"
              :to="{ name: 'Newspaper' }"
            ></b-menu-item> -->

            <b-menu-item
              icon="newspaper-variant-outline"
              label="E-Newspaper Articles"
              tag="router-link"
              :to="{ name: 'ENewspaper' }"
            ></b-menu-item>
          </b-menu-item>

          <b-menu-item icon="book-plus-multiple">
            <template #label="props">
              Issuances
              <b-icon
                class="is-pulled-right"
                :icon="props.expanded ? '' : ''"
              ></b-icon>
            </template>
            <b-menu-item
              icon="book-plus-multiple"
              label="Active Issuances"
              tag="router-link"
              :to="{ name: 'ActiveIssuances' }"
            ></b-menu-item>

            <b-menu-item
              icon="book-plus-multiple"
              label="Issuance History"
              tag="router-link"
              :to="{ name: 'HistoryIssuances' }"
            ></b-menu-item>
          </b-menu-item>

          <b-menu-item
            icon="account"
            label="Users"
            tag="router-link"
            :to="{ name: 'Users' }"
          ></b-menu-item>

          <b-menu-item
            icon="credit-card-check-outline"
            label="Subscriptions"
            tag="router-link"
            :to="{ name: 'SubscriptionsAdmin' }"
          ></b-menu-item>

          <b-menu-item v-if="false" icon="bank" label="Finances"></b-menu-item>

          <b-menu-item
            v-if="false"
            icon="file-document-multiple"
            label="Reports"
          ></b-menu-item>
        </b-menu-list>

        <b-menu-list label="External Resources">
          <b-menu-item
            icon="newspaper-variant-multiple"
            label="External Resources"
            tag="router-link"
            :to="{ name: 'ExternalResource' }"
          ></b-menu-item>
          <b-menu-item
            icon="notebook-multiple"
            label="Customize Issues"
            tag="router-link"
            :to="{ name: 'CustomMagazine' }"
          ></b-menu-item>
        </b-menu-list>

        <b-menu-list label="Customize">
          <b-menu-item
            icon="view-carousel-outline"
            label="Home Slider"
            tag="router-link"
            :to="{ name: 'CustomSlider' }"
          ></b-menu-item>
          <b-menu-item
            icon="image-multiple-outline"
            label="Home Tiles"
            tag="router-link"
            :to="{ name: 'CustomTiles' }"
          ></b-menu-item>
          <b-menu-item
            icon="trending-up"
            label="Home Trending"
            tag="router-link"
            :to="{ name: 'CustomTrending' }"
          ></b-menu-item>

          <b-menu-item
            icon="image-multiple-outline"
            label="Home Gallery"
            tag="router-link"
            :to="{ name: 'HomeGallery' }"
          ></b-menu-item>
          <b-menu-item
            icon="bulletin-board"
            label="Notice"
            tag="router-link"
            :to="{ name: 'CustomNotice' }"
          ></b-menu-item>
        </b-menu-list>

        <b-menu-list label="Account">
          <b-menu-item v-if="false" icon="cog" label="Settings"></b-menu-item>

          <b-menu-item
            icon="logout"
            label="Logout"
            @click.native="store.dispatch('logout')"
          ></b-menu-item>
        </b-menu-list>
      </b-menu>
    </div>
  </b-sidebar>
</template>

<script>
import store from "@/store";

export default {
  name: "App",
  props: {
    hidden: { type: Boolean, default: false }
  },
  data() {
    return {
      store,
      expandOnHover: false,
      expandWithDelay: false,
      mobile: "reduce",
      reduce: false
    };
  },
  computed: {
    userDetail() {
      return this.$store.state.userDetail;
    }
  },
  async created() {
    if (localStorage.accessToken) {
      await this.$store.dispatch("getUserDetail");
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";

.sidebar-hidden {
  display: none;
}

.sidebar-visible {
  display: block;
}

.p-1 {
  padding: 1em;
}
.b-sidebar .sidebar-content.is-fullheight {
  background-color: $secondary-color !important;
  min-height: 100vh;
  position: absolute;
}
.menu-label {
  color: $white !important;
}
.menu-list a {
  color: $white !important;
}
.menu-list a:hover {
  color: $white !important;
  background-color: $secondary-color-overlay !important;
}
.menu-list a.is-active {
  background-color: $secondary-color-overlay !important;
  color: $white !important;
}

.menu-list a.is-active.is-expanded.icon-text {
  background-color: $secondary-color !important;
  color: $white !important;
}

.menu-list a.router-link-exact-active {
  background-color: $white !important;
  color: $secondary-color !important;
}

.sidebar-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  // min-height: 100vh;
  .sidebar-layout {
    display: flex;
    flex-direction: row;
    min-height: 100%;
    // min-height: 100vh;
  }
}
@media screen and (max-width: 1023px) {
  .b-sidebar {
    .sidebar-content {
      &.is-mini-mobile {
        &:not(.is-mini-expand),
        &.is-mini-expand:not(:hover):not(.is-mini-delayed) {
          .menu-list {
            li {
              a {
                span:nth-child(2) {
                  display: none;
                }
              }
              ul {
                padding-left: 0;
                li {
                  a {
                    display: inline-block;
                  }
                }
              }
            }
          }
          .menu-label:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .b-sidebar {
    .sidebar-content {
      &.is-mini {
        &:not(.is-mini-expand),
        &.is-mini-expand:not(:hover):not(.is-mini-delayed) {
          .menu-list {
            li {
              a {
                span:nth-child(2) {
                  display: none;
                }
              }
              ul {
                padding-left: 0;
                li {
                  a {
                    display: inline-block;
                  }
                }
              }
            }
          }
          .menu-label:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
.is-mini-expand {
  .menu-list a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
