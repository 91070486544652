import axios from "axios";
import Nofitcation from "@/services/notificationService";
import store from "@/store";

const api = axios.create({
  baseURL: "https://readers-lounge-api.herokuapp.com/" //  "http://localhost:5000/" //
});

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      Nofitcation.error(`You must be logged in to continue`);
      store.dispatch("logout");
    }
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
  }
);

export default api;
