import Vue from "vue";
import Vuex from "vuex";
import api from "@/services/dataService";
import Notification from "@/services/notificationService";
import router from "@/router";

Vue.use(Vuex);
function defaultState() {
  return {
    userRole: undefined,
    currentUser: {
      role: "free"
    },
    isLoggedIn: false,
    autoComplete: undefined,
    userDetail: undefined,
    users: undefined,
    digitalMedia: undefined,
    books: undefined,
    eBooks: undefined,
    research: undefined,
    eResearch: undefined,
    journals: undefined,
    eJournals: undefined,
    newspaper: undefined,
    eNewspaper: undefined,
    externalResource: undefined,
    dashboardOverview: undefined,
    resourceUrl: undefined,
    issuedBooks: undefined,
    sliderObjects: undefined,
    sliderObject: undefined,
    tileObjects: undefined,
    tileObject: undefined,
    noticeObject: undefined,
    trendingObjects: undefined,
    trendingObject: undefined,
    magazineObjects: undefined,
    magazineObject: undefined,
    digitalMediaPaginated: undefined
  };
}

export default new Vuex.Store({
  state: defaultState,
  mutations: {
    setIsLoggedIn(state, payload) {
      state.isLoggedIn = payload;
    },
    setAutoComplete(state, payload) {
      state.autoComplete = payload;
    },
    setUserDetail(state, payload) {
      state.userDetail = payload;
    },
    setUsers(state, payload) {
      state.users = payload;
    },
    setDigitalMedia(state, payload) {
      state.digitalMedia = payload;
    },
    setExternalResource(state, payload) {
      state.externalResource = payload;
    },
    setBooks(state, payload) {
      state.books = payload;
    },
    setEBooks(state, payload) {
      state.eBooks = payload;
    },
    setResearch(state, payload) {
      state.research = payload;
    },
    setEResearch(state, payload) {
      state.eResearch = payload;
    },
    setJournals(state, payload) {
      state.journals = payload;
    },
    setEJournals(state, payload) {
      state.eJournals = payload;
    },
    setNewspaper(state, payload) {
      state.newspaper = payload;
    },
    setENewspaper(state, payload) {
      state.eNewspaper = payload;
    },
    setDashboardOverview(state, payload) {
      state.dashboardOverview = payload;
    },
    setResourceUrl(state, payload) {
      state.resourceUrl = payload;
    },
    setIssuances(state, payload) {
      state.issuedBooks = payload;
    },
    setSliderObjects(state, payload) {
      state.sliderObjects = payload;
    },
    setSliderObject(state, payload) {
      state.sliderObject = payload;
    },
    setTileObjects(state, payload) {
      state.tileObjects = payload;
    },
    setTileObject(state, payload) {
      state.tileObject = payload;
    },
    setNoticeObject(state, payload) {
      state.noticeObject = payload;
    },
    setTrendingObjects(state, payload) {
      state.trendingObjects = payload;
    },
    setTrendingObject(state, payload) {
      state.trendingObject = payload;
    },
    setDigitalMediaPaginated(state, payload) {
      state.digitalMediaPaginated = payload;
    },
    setUserRole(state, payload) {
      state.userRole = payload;
    },
    setCurrentUser(state, payload) {
      state.currentUser = payload;
    },
    setMagazineObject(state, payload) {
      state.magazineObject = payload;
    },
    setMagazineObjects(state, payload) {
      state.magazineObjects = payload;
    }
  },
  actions: {
    setCurrentUser(state, request) {
      state.commit("setCurrentUser", request);
    },
    setUserRole(state, request) {
      state.commit("setUserRole", request);
    },
    login(state, request) {
      return api
        .post("/user/auth", request)
        .then(res => {
          if (res.status === 200) {
            api.defaults.headers.common["x-auth-token"] = res.data.accessToken;
            localStorage.accessToken = res.data.accessToken;
            localStorage.refreshToken = res.data.refreshToken;
            localStorage.userRole = res.data.user.role;
            localStorage.userId = res.data.user.id;
            localStorage.userName =
              res.data.user.firstName + " " + res.data.user.lastName;
            state.commit("setIsLoggedIn", true);
            state.commit("setCurrentUser", {
              name: res.data.user.firstName + " " + res.data.user.lastName,
              role: res.data.user.role,
              id: res.data.user.id
            });
            if (res.data.user.role == "admin") {
              router.push({ name: "Dashboard" });
            } else {
              router.push({ name: "Home" });
            }
            return true;
          }
        })
        .catch(() => {
          return false;
        });
    },
    logout(state) {
      state.commit("setCurrentUser", {
        name: undefined,
        role: "free",
        id: undefined
      });
      localStorage.clear();
      api.defaults.headers.common["x-auth-token"] = undefined;
      router.push({ name: "Login" });
    },
    getAutoComplete(state) {
      return api
        .get("/app/results/autocomplete")
        .then(res => {
          const onlyUnique = (value, index, self) => {
            return self.indexOf(value) === index;
          };
          const filteredAutoComplete = {
            authors: res.data.authors.filter(onlyUnique),
            keywords: res.data.keywords.filter(onlyUnique),
            titles: res.data.titles.filter(onlyUnique)
          };
          state.commit("setAutoComplete", filteredAutoComplete);
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    getUserDetail(state) {
      return api
        .get("/user/details")
        .then(res => {
          state.commit("setUserDetail", res.data);
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    getDashboardOverview(state) {
      return api
        .get("/dashboard/dashboard")
        .then(res => {
          state.commit("setDashboardOverview", res.data);
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    getDigitalMedia(state) {
      return api
        .get("/digital/digital-media/")
        .then(res => {
          state.commit("setDigitalMedia", res.data);
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    addDigitalMedia(state, request) {
      return api
        .post("/digital/digital-media", request, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(res => {
          Notification.success("Digital Media Content added");
          return res.data;
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    editDigitalMedia(state, { id, request }) {
      return api
        .post("/digital/digital-media/" + id, request, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(() => {
          Notification.success("Digital Media Content updated");
          return true;
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    deleteDigitalMedia(state, itemId) {
      return api
        .delete("/digital/digital-media/" + itemId)
        .then(() => {
          Notification.success("Digital Media Content deleted");
          state.dispatch("getDigitalMedia");
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    extendUserSubscription(state, request) {
      return api
        .post("/subscription/", request)
        .then(() => {
          Notification.success("User subscription extended/created");
          state.dispatch("getUsers");
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    getExternalResource(state) {
      return api
        .get("/externalResource/external-resource")
        .then(res => {
          state.commit("setExternalResource", res.data);
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    addExternalResource(state, request) {
      return api
        .post("/externalResource/external-resource", request, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(res => {
          Notification.success("External Resoruce added");
          return res.data;
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    editExternalResource(state, { id, request }) {
      return api
        .post("/externalResource/external-resource/" + id, request, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(() => {
          Notification.success("External Resoruce updated");
          return true;
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    deleteExternalResource(state, itemId) {
      return api
        .delete("/externalResource/external-resource/" + itemId)
        .then(() => {
          Notification.success("External Resoruce deleted");
          state.dispatch("getExternalResource");
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    getBooks(state) {
      return api
        .get("/books/printed-book")
        .then(res => {
          state.commit("setBooks", res.data);
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    addBook(state, request) {
      return api
        .post("/books/printed-book", request)
        .then(res => {
          Notification.success("Book added");
          return res.data;
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    editBook(state, { id, request }) {
      return api
        .post("/books/printed-book/" + id, request)
        .then(() => {
          Notification.success("Book updated");
          return true;
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    deleteBook(state, itemId) {
      return api
        .delete("/books/printed-book/" + itemId)
        .then(() => {
          Notification.success("Book deleted");
          state.dispatch("getBooks");
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    getEBooks(state) {
      return api
        .get("/books/e-book")
        .then(res => {
          state.commit("setEBooks", res.data);
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    addEBook(state, request) {
      return api
        .post("/books/e-book", request)
        .then(res => {
          Notification.success("E-Book added");
          return res.data;
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    editEBook(state, { id, request }) {
      return api
        .post("/books/e-book/" + id, request)
        .then(() => {
          Notification.success("E-Book updated");
          return true;
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    deleteEBook(state, itemId) {
      return api
        .delete("/books/e-book/" + itemId)
        .then(() => {
          Notification.success("E-Book deleted");
          state.dispatch("getEBooks");
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    getResearch(state) {
      return api
        .get("/research/research-article")
        .then(res => {
          state.commit("setResearch", res.data);
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    addResearch(state, request) {
      return api
        .post("/research/research-article", request)
        .then(() => {
          Notification.success("Research added");
          return true;
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    editResearch(state, { id, request }) {
      return api
        .post("/research/research-article/" + id, request)
        .then(() => {
          Notification.success("Research updated");
          return true;
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    deleteResearch(state, itemId) {
      return api
        .delete("/research/research-article/" + itemId)
        .then(() => {
          Notification.success("Research deleted");
          state.dispatch("getResearch");
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    getEResearch(state) {
      return api
        .get("/research/eresearch-article")
        .then(res => {
          state.commit("setEResearch", res.data);
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    addEResearch(state, request) {
      return api
        .post("/research/eresearch-article", request)
        .then(res => {
          Notification.success("E-Research added");
          return res.data;
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    editEResearch(state, { id, request }) {
      return api
        .post("/research/eresearch-article/" + id, request)
        .then(() => {
          Notification.success("E-Research updated");
          return true;
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    deleteEResearch(state, itemId) {
      return api
        .delete("/research/eresearch-article/" + itemId)
        .then(() => {
          Notification.success("E-Research deleted");
          state.dispatch("getEResearch");
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    getJournals(state) {
      return api
        .get("/journal/journal-article")
        .then(res => {
          state.commit("setJournals", res.data);
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    addJournal(state, request) {
      return api
        .post("/journal/journal-article", request)
        .then(() => {
          Notification.success("Journal added");
          return true;
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    editJournal(state, { id, request }) {
      return api
        .post("/journal/journal-article/" + id, request)
        .then(() => {
          Notification.success("Journal updated");
          return true;
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    deleteJournal(state, itemId) {
      return api
        .delete("/journal/journal-article/" + itemId)
        .then(() => {
          Notification.success("Journal deleted");
          state.dispatch("getJournals");
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    getEJournals(state) {
      return api
        .get("/journal/ejournal-article")
        .then(res => {
          state.commit("setEJournals", res.data);
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    addEJournal(state, request) {
      return api
        .post("/journal/ejournal-article", request)
        .then(res => {
          Notification.success("E-Journal added");
          return res.data;
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    editEJournal(state, { id, request }) {
      return api
        .post("/journal/ejournal-article/" + id, request)
        .then(() => {
          Notification.success("E-Journal updated");
          return true;
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    deleteEJournal(state, itemId) {
      return api
        .delete("/journal/ejournal-article/" + itemId)
        .then(() => {
          Notification.success("E-Journal deleted");
          state.dispatch("getEJournals");
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    getNewspaper(state) {
      return api
        .get("/newspaper/newspaper-article")
        .then(res => {
          state.commit("setNewspaper", res.data);
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    addNewspaper(state, request) {
      return api
        .post("/newspaper/newspaper-article", request)
        .then(() => {
          Notification.success("Newspaper article added");
          return true;
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    editNewspaper(state, { id, request }) {
      return api
        .post("/newspaper/newspaper-article/" + id, request)
        .then(() => {
          Notification.success("Newspaper article updated");
          return true;
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    deleteNewspaper(state, itemId) {
      return api
        .delete("/newspaper/newspaper-article/" + itemId)
        .then(() => {
          Notification.success("Newspaper article deleted");
          state.dispatch("getNewspaper");
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    getENewspaper(state) {
      return api
        .get("/newspaper/eNewspaper-article")
        .then(res => {
          state.commit("setENewspaper", res.data);
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    addENewspaper(state, request) {
      return api
        .post("/newspaper/eNewspaper-article", request)
        .then(res => {
          Notification.success("E-Newspaper article added");
          return res.data;
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    editENewspaper(state, { id, request }) {
      return api
        .post("/newspaper/eNewspaper-article/" + id, request)
        .then(() => {
          Notification.success("E-Newspaper article updated");
          return true;
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    deleteENewspaper(state, itemId) {
      return api
        .delete("/newspaper/eNewspaper-article/" + itemId)
        .then(() => {
          Notification.success("E-Newspaper article deleted");
          state.dispatch("getENewspaper");
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    getUsers(state) {
      return api
        .get("/user/details/all")
        .then(res => {
          state.commit("setUsers", res.data);
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    addUser(state, request) {
      if (request.public) {
        return api
          .post("/user/register/public", request)
          .then(res => {
            Notification.success("Sign Up Successful");
            return res.data;
          })
          .catch(err => {
            Notification.error(
              Array.isArray(err.message) ? err.message[0] : err.message
            );

            return { errorMessages: err.message };
          });
      } else {
        return api
          .post("/user/register", request)
          .then(res => {
            Notification.success("User added");
            return res.data;
          })
          .catch(err => {
            Notification.error(
              Array.isArray(err.message) ? err.message[0] : err.message
            );
            return false;
          });
      }
    },
    editUser(state, { id, request }) {
      return api
        .post("/user/details/" + id, request)
        .then(() => {
          Notification.success("User updated");
          return true;
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    deleteUser(state, itemId) {
      return api
        .delete("/user/details/" + itemId)
        .then(() => {
          Notification.success("User deleted");
          state.dispatch("getUsers");
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },

    getIssuances(state, type) {
      return api
        .get("/issuance/?type=" + type)
        .then(res => {
          state.commit("setIssuances", res.data);
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    issueBook(state, request) {
      return api
        .post("/issuance", request)
        .then(() => {
          Notification.success("Book Issued");
          return true;
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return false;
        });
    },
    returnBook(state, request) {
      return api
        .post("issuance/return-book", request)
        .then(res => {
          Notification.success("Book returned");
          return res.data;
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    // getIssuedBook(state, id) {
    //   return api
    //     .get("/issuance/issuance/" + id)
    //     .then((res) => {
    //       Notification.success("Issued book fetched");
    //       return res.data;
    //     })
    //     .catch(err => {
    //       Notification.error(
    //         Array.isArray(err.message) ? err.message[0] : err.message
    //       );
    //     });
    // },

    updateSlider(state, request) {
      return api
        .post("/slider/slider/customize-slider", request, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(() => {
          Notification.success("Slider updated");
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message.msg
          );
        });
    },

    getSliderObjects(state) {
      return api
        .get("/slider/slider/")
        .then(res => {
          state.commit("setSliderObjects", res.data);
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    getSliderObject(state, sliderNo) {
      return api
        .get("/slider/slider/" + sliderNo)
        .then(res => {
          state.commit("setSliderObject", res.data);
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },

    updateTile(state, request) {
      return api
        .post("/tile/tile/customize-tile", request, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(() => {
          Notification.success("Tile updated");
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message.msg
          );
        });
    },

    getTileObjects(state) {
      return api
        .get("/tile/tile/")
        .then(res => {
          state.commit("setTileObjects", res.data);
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    getTileObject(state, tileNo) {
      return api
        .get("/tile/tile/" + tileNo)
        .then(res => {
          state.commit("setTileObject", res.data);
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },

    updateNotice(state, request) {
      return api
        .post("/notifications/notice/customize-notice", request)
        .then(() => {
          Notification.success("Notice updated");
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message.msg
          );
        });
    },

    getNoticeObject(state) {
      return api
        .get("/notifications/notice/")
        .then(res => {
          state.commit("setNoticeObject", res.data);
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },

    updateTrending(state, request) {
      return api
        .post("/trending/trending/customize-trending", request)
        .then(() => {
          Notification.success("Trending updated");
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message.msg
          );
        });
    },

    getTrendingObjects(state) {
      return api
        .get("/trending/trending/")
        .then(res => {
          state.commit("setTrendingObjects", res.data);
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    getTrendingObject(state, trendingNo) {
      return api
        .get("/trending/trending/" + trendingNo)
        .then(res => {
          state.commit("setTrendingObject", res.data);
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    updateMagazine(state, request) {
      return api
        .post("/magazine/magazine/customize-magazine", request, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(() => {
          Notification.success("Magazine updated");
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message.msg
          );
        });
    },

    getMagazineObjects(state) {
      return api
        .get("/magazine/magazine/")
        .then(res => {
          state.commit("setMagazineObjects", res.data);
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    getMagazineObject(state, magazineNo) {
      return api
        .get("/magazine/magazine/" + magazineNo)
        .then(res => {
          state.commit("setMagazineObject", res.data);
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },

    coverUpload(state, uploadRequest) {
      return api
        .post("/common/s3/coverUpload", uploadRequest, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(() => {})
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message.msg
          );
        });
    },
    attachmentUpload(state, uploadRequest) {
      return api
        .post("/common/s3/attachmentUpload", uploadRequest, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(() => {})
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message.msg
          );
        });
    },
    resourceDelete(state, request) {
      return api
        .post("/common/s3/delete", request)
        .then(() => {})
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message.msg
          );
        });
    },
    getResourceUrl(state, request) {
      return api
        .post("/common/s3/getAttachment", request)
        .then(res => {
          state.commit("setResourceUrl", res.data);
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message.msg
          );
        });
    },
    //page=0&perpage=4&sortBy=title&sortOrder=asc
    getDigitalMediaPaginated(state, request) {
      return api
        .get("/app/results/search", { params: request })
        .then(res => {
          state.commit("setDigitalMediaPaginated", res.data);
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message.msg
          );
        });
    },
    setResourceUrl(state, value) {
      state.commit("setResourceUrl", value);
    }
  },
  modules: {},
  getters: {}
});
