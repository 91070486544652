const adminRoutes = [
  {
    path: "/admin/",
    name: "Index",
    redirect: {
      name: "Dashboard"
    }
  },
  {
    path: "/admin/dashboard",
    name: "Dashboard",
    meta: {
      requiresAuth: true,
      showSidebar: true
    },
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "../../views/admin/Dashboard.vue"
      )
  },
  {
    path: "/add",
    name: "AddContent",
    component: () =>
      import(
        /* webpackChunkName: "DigitalMedia" */ "../../views/admin/addContent/AddContent.vue"
      ),
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: "/admin/digitalmedia",
    name: "DigitalMedia",
    component: () =>
      import(
        /* webpackChunkName: "DigitalMedia" */ "../../views/admin/digitalmedia/Viewer.vue"
      ),
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: "/admin/digitalmedia/editor",
    name: "DigitalMediaEditor",
    component: () =>
      import(
        /* webpackChunkName: "DigitalMedia" */ "../../views/admin/digitalmedia/Editor.vue"
      ),
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: "/admin/books",
    name: "Books",
    component: () =>
      import(
        /* webpackChunkName: "Books" */ "../../views/admin/books/Viewer.vue"
      ),
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: "/admin/books/editor",
    name: "BooksEditor",
    component: () =>
      import(
        /* webpackChunkName: "Books" */ "../../views/admin/books/Editor.vue"
      ),
    props: true,
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: "/admin/ebooks",
    name: "EBooks",
    component: () =>
      import(
        /* webpackChunkName: "Books" */ "../../views/admin/ebooks/Viewer.vue"
      ),
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: "/admin/ebooks/editor",
    name: "EBooksEditor",
    component: () =>
      import(
        /* webpackChunkName: "Books" */ "../../views/admin/ebooks/Editor.vue"
      ),
    props: true,
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: "/admin/research",
    name: "Research",
    component: () =>
      import(
        /* webpackChunkName: "Research" */ "../../views/admin/research/Viewer.vue"
      ),
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: "/admin/research/editor",
    name: "ResearchEditor",
    component: () =>
      import(
        /* webpackChunkName: "Research" */ "../../views/admin/research/Editor.vue"
      ),
    props: true,
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: "/admin/eresearch",
    name: "EResearch",
    component: () =>
      import(
        /* webpackChunkName: "EResearch" */ "../../views/admin/eresearch/Viewer.vue"
      ),
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: "/admin/eresearch/editor",
    name: "EResearchEditor",
    component: () =>
      import(
        /* webpackChunkName: "EResearch" */ "../../views/admin/eresearch/Editor.vue"
      ),
    props: true,
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: "/admin/journals",
    name: "Journals",
    component: () =>
      import(
        /* webpackChunkName: "Journals" */ "../../views/admin/journals/Viewer.vue"
      ),
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: "/admin/journals/editor",
    name: "JournalsEditor",
    component: () =>
      import(
        /* webpackChunkName: "Journals" */ "../../views/admin/journals/Editor.vue"
      ),
    props: true,
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: "/admin/ejournals",
    name: "EJournals",
    component: () =>
      import(
        /* webpackChunkName: "eJournals" */ "../../views/admin/ejournals/Viewer.vue"
      ),
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: "/admin/ejournals/editor",
    name: "EJournalsEditor",
    component: () =>
      import(
        /* webpackChunkName: "eJournals" */ "../../views/admin/ejournals/Editor.vue"
      ),
    props: true,
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  // {
  //   path: "/admin/newspaper",
  //   name: "Newspaper",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "Newspaper" */ "../../views/admin/newspaper/Viewer.vue"
  //     ),
  //   meta: {
  //     requiresAuth: true,
  //     showSidebar: true
  //   }
  // },
  {
    path: "/admin/newspaper/editor",
    name: "NewspaperEditor",
    component: () =>
      import(
        /* webpackChunkName: "Newspaper" */ "../../views/admin/newspaper/Editor.vue"
      ),
    props: true,
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: "/admin/enewspaper",
    name: "ENewspaper",
    component: () =>
      import(
        /* webpackChunkName: "eNewspaper" */ "../../views/admin/enewspaper/Viewer.vue"
      ),
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: "/admin/enewspaper/editor",
    name: "ENewspaperEditor",
    component: () =>
      import(
        /* webpackChunkName: "eNewspaper" */ "../../views/admin/enewspaper/Editor.vue"
      ),
    props: true,
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: "/admin/externalresource",
    name: "ExternalResource",
    component: () =>
      import(
        /* webpackChunkName: "externalResource" */ "../../views/admin/externalresource/Viewer.vue"
      ),
    props: true,
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: "/admin/externalresource/editor",
    name: "ExternalResourceEditor",
    component: () =>
      import(
        /* webpackChunkName: "externalResource" */ "../../views/admin/externalresource/Editor.vue"
      ),
    props: true,
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: "/admin/users",
    name: "Users",
    component: () =>
      import(
        /* webpackChunkName: "Users" */ "../../views/admin/users/Viewer.vue"
      ),
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: "/admin/subscriptions",
    name: "SubscriptionsAdmin",
    component: () =>
      import(
        /* webpackChunkName: "Users" */ "../../views/admin/subscriptions/Viewer.vue"
      ),
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: "/admin/user/issuances",
    name: "UserIssuances",
    component: () =>
      import(
        /* webpackChunkName: "Users" */ "../../views/admin/users/UserIssuances.vue"
      ),
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: "/admin/users/editor",
    name: "UsersEditor",
    component: () =>
      import(
        /* webpackChunkName: "Users" */ "../../views/admin/users/Editor.vue"
      ),
    props: true,
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: "/admin/issuances/active",
    name: "ActiveIssuances",
    component: () =>
      import(
        /* webpackChunkName: "ActiveIssuances" */ "../../views/admin/issuances/ActiveViewer.vue"
      ),
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: "/admin/issuances/history",
    name: "HistoryIssuances",
    component: () =>
      import(
        /* webpackChunkName: "HistoryIssuances" */ "../../views/admin/issuances/HistoryViewer.vue"
      ),
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: "/admin/slider",
    name: "CustomSlider",
    component: () =>
      import(
        /* webpackChunkName: "CustomSlider" */ "../../views/admin/slider/Editor.vue"
      ),
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: "/admin/tiles",
    name: "CustomTiles",
    component: () =>
      import(
        /* webpackChunkName: "CustomTiles" */ "../../views/admin/tiles/Editor.vue"
      ),
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: "/admin/notice",
    name: "CustomNotice",
    component: () =>
      import(
        /* webpackChunkName: "CustomNotice" */ "../../views/admin/notice/Editor.vue"
      ),
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: "/admin/magazine",
    name: "CustomMagazine",
    component: () =>
      import(
        /* webpackChunkName: "CustomMagazine" */ "../../views/admin/magazine/Editor.vue"
      ),
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: "/admin/home-gallery",
    name: "HomeGallery",
    component: () =>
      import(
        /* webpackChunkName: "CustomNotice" */ "../../views/admin/homegallery/Editor.vue"
      ),
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: "/admin/trending",
    name: "CustomTrending",
    component: () =>
      import(
        /* webpackChunkName: "CustomTrending" */ "../../views/admin/trending/Editor.vue"
      ),
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: "/admin/under-development",
    name: "UnderDevelopment",
    component: () => import("../../views/admin/UnderDevelopment.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/404",
    name: "NotFoundAdmin",
    component: () => import("../../views/admin/NotFound.vue")
  },
  {
    path: "/admin/*",
    redirect: "/404"
  }
];

export default adminRoutes;
