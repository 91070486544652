<template>
  <div>
    <link
      rel="stylesheet"
      href="https://cdn.jsdelivr.net/npm/@mdi/font@5.8.55/css/materialdesignicons.min.css"
    />
    <div class="sidebar-page">
      <section class="sidebar-layout">
        <Sidebar
          v-if="showSidebar"
          :class="!showSidebar ? 'sidebar-hidden' : 'sidebar-visible'"
        />
        <div
          class="app-container"
          v-bind:class="{
            'app-container-with-sidebar p-5': showSidebar,
            'dash-app-container': showSidebar
          }"
        >
          <router-view />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Sidebar from "./components/Sidebar.vue";

export default {
  name: "App",
  components: {
    Sidebar
  },
  data() {
    return {
      showSidebar: false,
      hidePDFViewer: true
    };
  },
  computed: {
    route() {
      return this.$route;
    },
    userRole() {
      return this.$store.state.userRole;
    }
  },
  updated() {
    console.log("updated");
    if (this.hidePDFViewer) {
      if (document.getElementById("pdf-view")) {
        document.getElementById("pdf-view").innerHTML = "";
        document.getElementById("pdf-view").removeAttribute("style");
        document.getElementById("pdf-view").style.display = "none";
      }
    }
  },
  watch: {
    route: function(val) {
      if (val.meta.showSidebar) this.showSidebar = val.meta.showSidebar;
      else this.showSidebar = false;

      if (val.meta.showPdfViewer) this.hidePDFViewer = false;
      else this.hidePDFViewer = true;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
