const appRoutes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../../views/app/Home.vue")
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "Login" */ "../../views/admin/Login.vue"),
    meta: {
      sidebarHidden: true
    }
  },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "Login" */ "../../views/admin/Register.vue"),
    meta: {
      sidebarHidden: true
    }
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "Login" */ "../../views/app/About.vue"),
    meta: {
      sidebarHidden: true
    }
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () =>
      import(
        /* webpackChunkName: "Login" */ "../../views/app/ForgotPassword.vue"
      ),
    meta: {
      sidebarHidden: true
    }
  },
  {
    path: "/explore",
    name: "Explore",
    component: () => import("../../views/app/Explore.vue")
  },
  {
    path: "/researchers",
    name: "Researchers",
    component: () => import("../../views/app/Researchers.vue")
  },
  {
    path: "/researcher-resources",
    name: "ResearcherResources",
    component: () => import("../../views/app/ResearcherResources.vue")
  },
  {
    path: "/search-engines/nyt",
    name: "NYT",
    component: () => import("../../views/app/NYT.vue")
  },
  {
    path: "/search-engines/outline",
    name: "Outline",
    component: () => import("../../views/app/Outline.vue")
  },
  {
    path: "/search-engines/guardian",
    name: "Guardian",
    component: () => import("../../views/app/Guardian.vue")
  },
  {
    path: "/subscriptions",
    name: "Subscriptions",
    component: () => import("../../views/app/Subscriptions.vue")
  },
  {
    path: "/search",
    name: "Search",
    component: () => import("../../views/app/Search.vue")
  },
  {
    path: "/detail",
    name: "ItemDetail",
    component: () => import("../../views/app/ItemDetail.vue")
  },
  {
    path: "/reader",
    name: "EBookReader",
    component: () => import("../../views/app/EBookReader.vue"),
    meta: {
      showPdfViewer: true
    }
  },
  {
    path: "/e-newspaper/reader",
    name: "MagazineReader",
    component: () => import("../../views/app/MagazineReader.vue"),
    meta: {
      showPdfViewer: true
    }
  },
  {
    path: "/my-issuances",
    name: "MyIssuances",
    component: () => import("../../views/app/MyIssuances.vue")
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: () => import("../../views/app/ChangePassword.vue")
  },
  {
    path: "/update-profile-pictutre",
    name: "ChangeProfilePicture",
    component: () => import("../../views/app/ChangeProfilePicture.vue")
  },
  {
    path: "/404",
    name: "NotFound",
    component: () => import("../../views/admin/NotFound.vue")
  },
  {
    path: "/*",
    redirect: "/admin/404"
  }
];

export default appRoutes;
