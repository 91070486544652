import Vue from "vue";
import VueRouter from "vue-router";
import Nprogress from "nprogress";
import "nprogress/nprogress.css";
import store from "@/store";
import adminRoutes from "./routes/admin";
import appRoutes from "./routes/app";

Vue.use(VueRouter);

const routes = adminRoutes.concat(appRoutes);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "is-active"
});

router.beforeEach(async (to, _from, next) => {
  // check if route is marked as requiresAuth in metadata
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.accessToken) {
      next();
    } else {
      store.dispatch("logout");
    }
  } else {
    next();
  }
});

router.beforeResolve((to, _from, next) => {
  // enforce https
  const url = window.location.href;
  if (url.slice(0, 5) != "https") {
    window.location.href = "https:" + url.slice(5, url.length + 1);
  }

  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    Nprogress.start();
  }

  // admin route protection
  if (to.meta && to.meta.requiresAuth) {
    if (store.state.currentUser.role != "admin") {
      router.push({ name: "Home" });
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach(() => {
  window.scrollTo(0, 0);
  // Complete the animation of the route progress bar.
  Nprogress.done();
});

// Resolve for NavigationDuplicated Errors
// router.push = async function(location) {
//   let route;
//   try {
//     route = await VueRouter.prototype.push.call(this, location);
//   } catch (err) {
//       // override erros if needed
//     if (err.name !== "NavigationDuplicated") {
//       throw err;
//     }
//   }
//   return route;
// };
// router.replace = async function(location) {
//   let route
//   try {
//     route = await VueRouter.prototype.push.call(this, location);
//   } catch (err) {
//       // override erros if needed
//     if (err.name !== "NavigationDuplicated") {
//       throw err;
//     }
//   }
//   return route;
// };

export default router;
