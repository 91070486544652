import Vue from "vue";
import App from "./App.vue";
import Buefy from "buefy";
import "buefy/dist/buefy.css";
import router from "./router";
import store from "./store";
import api from "@/services/dataService";
import "@/common/filters";
import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: "G-6B3GNLF718" }
});

Vue.use(Buefy);

Vue.config.productionTip = false;





if (localStorage.accessToken) {
  api.defaults.headers.common["x-auth-token"] = localStorage.accessToken;
  store.dispatch("setUserRole");
  store.dispatch("setCurrentUser", {
    name: localStorage.userName,
    role: localStorage.userRole,
    id: localStorage.userId
  });
} else {
  localStorage.clear();
  api.defaults.headers.common["x-auth-token"] = undefined;
}
store.dispatch("getAutoComplete");

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");


