import Vue from "vue";
import { DateTime, Duration } from "luxon";

function isIsoDate(str) {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
  var d = new Date(str);
  return d.toISOString() === str;
}

Vue.filter("filter", value => {
  if (value == null || value === 0 || value.length < 1) {
    return "-";
  } else if (isIsoDate(value)) {
    return DateTime.fromISO(value).toFormat("dd/MM/yyyy");
    //return DateTime.fromISO(value).toFormat("hh:mm a, dd/MM/yyyy");
  } else {
    return value;
  }
});

Vue.filter("date", (value, type = "default") => {
  if (value == null) {
    return "-";
  } else if (type == "date-time") {
    return DateTime.fromISO(value).toFormat("dd/MM/yyyy, hh:mm a");
  } else if (type == "time-date") {
    return DateTime.fromISO(value).toFormat("hh:mm a, dd/MM/yyyy");
  } else if (type == "date-time-seconds") {
    return DateTime.fromISO(value).toFormat("dd LLL yyyy, hh:mm:ss a");
  } else if (type == "time-date-seconds-no-year") {
    return DateTime.fromISO(value).toFormat("hh:mm:ss a, dd LLL");
  } else if (type == "time-date-seconds") {
    return DateTime.fromISO(value).toFormat("hh:mm:ss a, dd/MM/yyyy");
  } else if (type == "time") {
    return DateTime.fromISO(value).toFormat("hh:mm a");
  } else if (type == "time-seconds") {
    return DateTime.fromISO(value).toFormat("hh:mm:ss a");
  } else if (type == "day") {
    return DateTime.fromISO(value).toFormat("EEEE");
  } else if (type == "numeric-short") {
    return DateTime.fromISO(value).toFormat("dd/MM");
  } else if (type == "short") {
    return DateTime.fromISO(value).toLocaleString({
      weekday: "short",
      month: "short",
      day: "2-digit"
    });
  } else if (type == "short-with-day") {
    return DateTime.fromISO(value).toLocaleString({
      weekday: "short",
      month: "short",
      year: "numeric",
      day: "2-digit"
    });
  } else if (type == "medium") {
    return DateTime.fromISO(value).toLocaleString(DateTime.DATE_MED);
  } else if (type == "time-date-medium") {
    return (
      DateTime.fromISO(value).toFormat("hh:mm a, ") +
      DateTime.fromISO(value).toLocaleString(DateTime.DATE_MED)
    );
  } else {
    // Default filter for date
    return DateTime.fromISO(value).toFormat("dd/MM/yyyy");
  }
});

Vue.filter("timeDuration", (value, endTime) => {
  const duration = Duration.fromObject(
    DateTime.fromISO(endTime)
      .diff(DateTime.fromISO(value), "minutes")
      .toObject()
  );
  let formattedDuration = undefined;
  if (duration.minutes > 60) {
    formattedDuration = duration.toFormat("hh Hr mm") + " Mins";
  } else if (duration.minutes < 1) {
    formattedDuration = "01 Mins";
  } else {
    formattedDuration = duration.toFormat("mm") + " Mins";
  }
  return formattedDuration;
});

Vue.filter("daysDuration", (value, endTime) => {
  const date1 = new Date(value);
  const date2 = new Date(endTime);
  const diffTime = (date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
  return diffDays>0?diffDays:0;
});

Vue.filter("formatNull", value => {
  if (value == null || value === 0) {
    return "-";
  } else {
    return value;
  }
});

Vue.filter("formatCamelCase", value => {
  if(value=="ISBN") return "ISBN"
  if(value=="ISSN") return "ISSN"
  const result = value.replace(/([A-Z])/g, " $1");
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
});


Vue.filter("capitalize", value => {
  if (!value) return "";
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("allCaps", value => {
  if (!value) return "";
  return value.toString().toUpperCase();
});

Vue.filter("limitTo", (value, limit) => {
  return value.slice(0, limit)+"...";
});

Vue.filter("formatType", value => {
  const formattedTypes = {
    PrintedBook:"Book",
    eBook: "E-Book",
    eJournal: "E-Journal",
    eNewspaper: "E-Newspaper",
    eResearch: "E-Research"
  }
  return formattedTypes[value] ? formattedTypes[value] : value;
});
